/**
 * Partners page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Cardpartner from "../components/cardpartner"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/partners.scss"


/* Page function declaration */
const Partnerspage = ({data}) => {

  //Const data ACF page
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPagePartners

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroPartners.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroPartners})
  ))

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "contact2"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero section */}
      <section className="section-hero-partners top-section">
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroPartners} arrIntro={arrTextHero} colorBar="blue" />
      </section>

      {/*partners details section */}
      <section className="section-details-partners">
        <div className="wrap-list-partners grid-loop-card-clickable">
          {dataAcf.repeaterPartners.map((partner, index) =>
            <Cardpartner
              key={index}
              logopartner={partner.logoPartner.localFile.childImageSharp.gatsbyImageData}
              titlepartner={partner.titlePartner}
              typepartner={partner.typePartner}
              urlpartner={partner.urlPartner}
            />
          )}
        </div>
      </section>

      {/* Banner CTA Suppot */}
      <Bannerwithcta
        classBanner="section-banner-partners"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "partners"}) {
      acfPagePartners {
        repeaterHeroPartners {
          introHeroPartners
        }
        repeaterPartners {
          logoPartner {
            localFile {
              childImageSharp {
                gatsbyImageData (layout: CONSTRAINED, width: 270)
              }
            }
          }
          titlePartner
          typePartner
          urlPartner
        }
        titleHeroPartners
      }
      title
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
/* Export page informations */
export default Partnerspage
