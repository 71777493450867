/**
 * Const with params element
 * Card partner component
 * Display a card of partner
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import "../styles/components/cardpartner.scss"

/* Declaration function */
const Cardpartner = ( { logopartner, titlepartner, typepartner, urlpartner } ) => {
  const extLinkCta = true

  return (
    <div className="card-partner card-clickable">
      <div className="box-image-card logo-partner">
        <GatsbyImage image={logopartner} alt={`logo ${titlepartner}`}/>
      </div>
      <h2>{titlepartner}</h2>
      <div className="type-partner large-body">
        <p>{typepartner}</p>
      </div>
      <div className="box-ctatext-card">
        <Ctabuttonwitharrow ctaLabel="See the Website" ctaLink={urlpartner} extLink={extLinkCta} specificStyle="text-arrow" colorStyle="white-format" />
      </div>
      <a href={urlpartner} aria-label="See the Website" target="_blank" rel="noreferrer"><span className="span-cta-card">See the Website</span></a>
    </div>
  )
}

/* Function propTypes declaration */
Cardpartner.propTypes = {
  titlepartner: PropTypes.string,
  typepartner: PropTypes.string,
  urlpartner: PropTypes.string,
}

Cardpartner.defaultProps = {
  titlepartner: "Company name",
  typepartner: "Type partner",
  urlpartner: "",
}


/* Export function */
export default Cardpartner
